.item-details {
  > div:first-child {
    min-height: 55vh
  }

  ion-item-divider {
    min-height: 1rem;
    margin-bottom: 0.5rem
  }

  h2 {
    font-weight: 500
  }

  .detail {
    --min-height: 28px
  }
}